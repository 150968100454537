<template>
  <div id="user-list">
    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Payments") }}<v-chip outlined class="ml-2">{{ totalItems }}</v-chip>
      </v-card-title>
      <v-divider class="mt-4"></v-divider>

      <!-- table -->
      <v-data-table
        class="fixed-table"
        fixed-header
        height="500"
        dense
        :headers="filters"
        :items="items"
        :options.sync="options"
        :page.sync="page"
        :server-items-length="totalItems"
        :loading="loading"
        hide-default-footer
        :show-select="false"
        :loading-text="$t('Loading data')"
        :no-data-text="$t('No data')"
        :no-results-text="$t('No result')"
        @page-count="pageCount = $event"
      >
        <template #body.prepend="{ items, headers }">
          <header-filters :headers="filters" />
        </template>
        <!-- actions -->
        <template #item="options">
          <Row
            :options="options"
            :show-select="true"
            completed-field="publication.state"
            :config="{ url: '/payments/action/', resource: 'Wish'}"
            @refetch-data=""
            @saved=""
            @select=""
          >
            <!-- name -->

            <template #[`item.user.name`]="{item}">
              <div class="d-flex align-center">
                <v-avatar
                  v-if="item.user"
                  :color="item.user.avatar ? '' : 'primary'"
                  :class="item.user.avatar ? '' : 'v-avatar-light-bg primary--text'"
                  size="30"
                >
                  <v-img
                    v-if="item.user.avatar"
                    :lazy-src="require(`@/assets/images/avatars/2.png`)"
                    :src="item.user.avatar || require(`@/assets/images/avatars/2.png`)"
                  ></v-img>
                  <span
                    v-else
                    class="font-weight-medium"
                  >{{ avatarText(item.user.name) }}</span>
                </v-avatar>

                <div
                  v-if="item.user"
                  class="d-flex flex-column ms-3"
                >
                  <router-link
                    :to="{ name : 'user-view', params : { id : item.user_id } }"
                    class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                  >
                    {{ item.user.name }}
                  </router-link>
                  <!--              <small>@{{ item.manuscriptname }}</small>-->
                </div>
              </div>
            </template>


            <template #[`item.description`]="{item}">
              <span v-html="shortHtml(item.description, 50)"></span>
            </template>
            <!-- actions -->
            <template #[`item.actions`]="{item}">
              <v-menu
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <!--              <v-list-item
                :to="{name:'ride-view',params:{id:item.id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>{{ $t('Details') }}</span>
                </v-list-item-title>
              </v-list-item>-->




<!--                  <v-list-item
                    v-if="$can('delete','Payment')"
                    @click="confirmDelete(item)"
                  >
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                      <span><span>{{ $t('Delete') }}</span></span>
                    </v-list-item-title>
                  </v-list-item>-->
                </v-list>
              </v-menu>
            </template>
          </Row>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <rows-per-page :options="options" />
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="page"
              total-visible="6"
              :length="pageCount"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiDotsVertical,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

// sidebar
import { avatarText, formatDate, formatDateToMonthShort } from '@core/utils/filter'
import axios from '@axios'
import store from '@/store'
import router from '@/router'
import RowsPerPage from '@/components/RowsPerPage'
import HeaderFilters from '@/components/HeaderFilters'
import Row from '@/components/Row.vue'


export default {
  methods: {
    avatarText() {
      return avatarText
    }
  },
  components: {
    Row,
    HeaderFilters,
    RowsPerPage,
  },
  setup() {
    const payments = ref('')
    const items = ref([])
    const userId = ref(null)
    const loadingPayments = ref(true)
    const isSnackbarBottomVisible = ref(false)
    const snackBarMessage = ref('')
    const isFormActive = ref(false)
    const searchQuery = ref('')
    const roleFilter = ref(null)
    const supervisorFilter = ref(null)
    const statusFilter = ref(null)
    const totalItems = ref(0)
    const loading = ref(false)
    const statuses = ref([
      { id: 0, label: 'Unpaid' },
      { id: 1, label: 'Confirmation' },
      { id: 2, label: 'Paid' },
      { id: 3, label: 'Transfered' },
    ])
    const filters = ref([
      {
        text: 'ID',
        value: 'id',
        width: '70px',
        filter: {
          place_holder: '', type: 'text',
        },
      },
      {
        text: 'User',
        value: 'user.name',
        width: '200px',
        filter: {
          place_holder: 'Name or phone...', type: 'text', columns: ['users.name', 'users.email', 'users.code'], join_table: 'users', join_column: 'users.id', local_column: 'payments.user_id',
        },
      },
       { text: 'Amount', value: 'amount' },
      {
        text: 'Type',
        value: 'payable_type',
        filter: {
          place_holder: '', type: 'text',
        },
      },
      {
        text: 'Type #',
        value: 'payable_id',
        filter: {
          place_holder: '', type: 'text',
        },
      },
      {
        text: 'Status',
        value: 'status',
        type: 'select',
        align: 'center',
        width: '200px',
        selected_value: 'status',
        selected_label: 'label',
        itemLabel: 'label',
        itemValue: 'id',
        items: statuses.value,
        comment_column: 'status',
        editable: true,
        info: 'Confirm the update of "Status" ?',
        column_name: 'status',
        fields: [
          {
            type: 'select',
            label: 'Status',
            value: 'status',
            items: statuses.value,
            item_text: 'label',
            item_value: 'id',
          },
        ],
        filter: {
          place_holder: '', type: 'select', column: 'status', values: statuses.value, label: 'label', id: 'id', multiple: false,
        },
      },
      {
        text: 'Description',
        value: 'description',
        filter: {
          place_holder: '', type: 'text',
        },
      },
      {
        text: 'IP',
        value: 'ip',
        filter: {
          place_holder: '', type: 'text',
        },
      },
      {
        text: 'Device',
        value: 'device',
        filter: {
          place_holder: '', type: 'text',
        },
      },
      {
        text: 'Browser',
        value: 'browser',
        filter: {
          place_holder: '', type: 'text',
        },
      },
      {
        text: 'Date',
        value: 'created_at',
        width: '120px',
        cellAlign: 'center',
        menu: false,
        type: 'date',
        editable: false,
        date_format: { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
        fields: [
          { type: 'date', label: 'Created At', value: 'created_at' },
        ],
        filter: {
          place_holder: 'Date1', type: 'date', range: true,
        },
        filters: [
          {
            key: 'created_at',
            text: 'Created At',
            place_holder: 'Date1 - Date2',
            type: 'date',
            menu: false,
            range: true,
          },
          {
            text: 'Condition',
            place_holder: 'Condition',
            type: 'select',
            is_condition: true,
            values: [
              { id: 0, label: 'Is empty' },
              { id: 1, label: 'Not empty' },
            ],
            label: 'label',
            id: 'id',
            multiple: false,
            key: 'ride_created_at',
            column: 'created_at',
          },
        ],
      }])
    const options = ref({
      itemsPerPage: 15,
      rowsPerPage: 15,
      page: 1,
      sortDesc: [true],
    })
    const page = ref(1)
    const pageCount = ref(0)
    const userTotalLocal = ref([])
    const selectedRows = ref([])

    if (router.currentRoute.params.id) {
      userId.value = router.currentRoute.params.id
    } else {
      userId.value = store.state.app.user.id
    }
    const fetchData = () => {
      axios.get('/admin/payments', {
        params: {
          filters: filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0)).map(ele => ({ ...ele.filter, key: ele.value, values: null })),

          term: searchQuery.value,
          userId: userId.value,
          orderBy: options.value.sortBy ? options.value.sortBy[0] : 'id',
          // eslint-disable-next-line no-nested-ternary
          direction: options.value.sortDesc ? (options.value.sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
          page: options.value.page,
          per_page: options.value.itemsPerPage,
        },
      }).then(response => {
        items.value = response.data.data
        totalItems.value = response.data.total
      }).then(() => {
        loading.value = false
      })
    }
    // eslint-disable-next-line no-unused-vars
    let timeout = null
    watch([filters], () => {
      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(() => {
        loading.value = true
        selectedRows.value = []
        fetchData()
      }, 500)
    }, { deep: true })
    watch([searchQuery, options], () => {
      loading.value = true
      selectedRows.value = []
      fetchData()
    }, { deep: true })
    fetchData()

    return {
      avatarText,
      payments,
      items,
      searchQuery,
      roleFilter,
      supervisorFilter,
      statusFilter,
      totalItems,
      loading,
      options,
      filters,
      userTotalLocal,
      selectedRows,
      isFormActive,
      page,
      pageCount,
      snackBarMessage,
      isSnackbarBottomVisible,
      loadingPayments,
      icons: {
        mdiDotsVertical,
      },

      formatDate,
      formatDateToMonthShort,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
